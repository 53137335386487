// extracted by mini-css-extract-plugin
export var ArtistDescription = "PeterSaul-module--ArtistDescription--JYm9F";
export var ButtonWrapper = "PeterSaul-module--ButtonWrapper --YCYir";
export var CardWrapper = "PeterSaul-module--CardWrapper--jrg-Z";
export var CarrouselWrapper = "PeterSaul-module--CarrouselWrapper--rpJec";
export var CarrouselWrapper2 = "PeterSaul-module--CarrouselWrapper2--Ni7yV";
export var DescriptionWrapper = "PeterSaul-module--DescriptionWrapper--hsqCl";
export var ExpoWrapper = "PeterSaul-module--ExpoWrapper--gXZDx";
export var LinkWrapper = "PeterSaul-module--LinkWrapper--VCFu0";
export var NameWrapper = "PeterSaul-module--NameWrapper--gxc8M";
export var PdpWrapper = "PeterSaul-module--PdpWrapper--PIGov";
export var PhotosWrapper = "PeterSaul-module--PhotosWrapper--Q1q90";
export var ProfilWrapper = "PeterSaul-module--ProfilWrapper--n42eJ";
export var TitleWrapper = "PeterSaul-module--TitleWrapper--ikjQa";
export var VideoWrapper = "PeterSaul-module--VideoWrapper--Ex1b6";
export var VideosWrapper = "PeterSaul-module--VideosWrapper--4fjT1";
export var WorkWrapper = "PeterSaul-module--WorkWrapper--+xLuk";
export var WorksWrapper = "PeterSaul-module--WorksWrapper--uq-p5";
export var Wrapper = "PeterSaul-module--Wrapper--Ch1ne";