import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CarrouselWrapper,
CarrouselWrapper2,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  ExpoWrapper,
  ButtonWrapper,
  TitleWrapper,
  CarrouselCartel
} from "./PeterSaul.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import PeterSaulPhoto1 from "../../../../res/Photos site/Peter saul/Peter-Saul_BorisConte-Photography-PeterSaul-LesAbattoirs-190919-01.jpg"
import PeterSaulPhoto2 from "../../../../res/Photos site/Peter saul/Peter-Saul by Eric Chakeen.webp"
import PeterSaulPhoto4 from "../../../../res/Photos site/Peter saul/Peter Saul.jpg"
import PeterSaulPhoto3 from "../../../../res/Photos site/Peter saul/Peter-Saul by Lexie Moreland.webp"
import Button from "./../../../../components/Button";
import Pdp from "../../../../res/saul/portrait.jpeg";
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "PeterSaul",
  name: "PeterSaul",
  description:"Peter Saul occupe une place symbolique dans l’histoire de l’art puisque qu’il est l’un des prédécesseurs du Pop art. Né à San Francisco en 1934, ses peintures reprennent des thèmes satiriques de la culture américaine. Après ses études aux Beaux-Arts de Californie entre 1950 et 1952, il part voyager en Europe et s’y installe en 1964. Peter Saul réalise une interprétation de divers sujets politiques tels que la guerre du Vietnam, ainsi que des portraits de politiciens historiques légèrement caricaturés et toujours avec des couleurs éclatantes. En 2010, il est élu à l’American Academy of Art and Letters. Il vit et travaille à New York et à Germantown dans l’État de New York. Son travail fait partie des collections de l’ Art Institute de Chicago, du Centre Pompidou, du Los Angeles County Museum of Art, du Metropolitan Museum of Art de New York et du Stedelijk Museum entre autres. ",
  pdp: Pdp,
  alt_pdp: "Photo de profil de PeterSaul.",
  photos: [
    { src: PeterSaulPhoto1, name: "PeterSaul" },
    { src: PeterSaulPhoto4, name: "PeterSaul" },
    { src: PeterSaulPhoto3, name: "PeterSaul" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const PeterSaul = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>PETER SAUL <h2 style={{paddingLeft: "16px"}}>(American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1934</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
          <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }}
            >
              Contact-us
            </Button>
        </div>
        </div>
      </div>
      
     
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
         
        </div>
      </div>
    </div>
  );
};

export default PeterSaul;